import React from 'react'
import { Switch, Route, useHistory } from 'react-router-dom';

import Home from '../pages/Home'
import Faq from '../pages/Faq'
import SignUp from '../pages/SignUp'
import Event from '../pages/Event'
import Shedule from '../pages/Shedule'
import OrganizingiCommitte from '../pages/OrganizingCommittee'
import StrategicCommitte from '../pages/StrategicCommitte'
import InternationalCommittee from '../pages/InternationalCommittee'
import TechnicalCommittee from '../pages/TechnicalCommittee'
import Sponsors from '../pages/Sponsors';
import Category from '../pages/Category'
import New from '../pages/New'
import PageNotFound from '../pages/PageNotFound'
import Seacrh from '../pages/Search';
import Posts from '../pages/Posts';
import PicturesRosen from '../pages/PicturesRosen';
import Expositors from '../pages/Expositors';
// import PicturesRosen from '../pages/PicturesRosen';
import ThankYouPage from '../pages/ThankYouPage';
import ThankYouPageExpo from '../pages/ThankYouPageExpo';

import knowTheCity from "../pages/knowTheCity"
import Speakers from '../pages/Speakers';
import Mapa from '../pages/Mapa';
import PartnerEvents from '../pages/PartnerEvents';

export default function Routes() {
  const history = useHistory()

  return (
    <Switch history={history} >
      <Route path={["/conheca-a-cidade", "/know-the-city"]} exact component={knowTheCity} />
      <Route path="/faq" exact component={Faq} />
      <Route path="/obrigado" exact component={ThankYouPage} />
      <Route path="/exposicao/obrigado" exact component={ThankYouPageExpo} />
      <Route path={["/inscreva-se", "/sign-up"]} component={SignUp} />
      {/* <Route path={["/o-evento", "/the-event"]} exact component={Event} /> */}
      <Route path={["/programacao", "/schedule"]} exact component={Shedule} />
      <Route path={["/expositores", "/exhibitors"]} exact component={Expositors} />
      <Route path={["/comite-organizador", "/organizing-committee"]} exact component={OrganizingiCommitte} />
      <Route path={["/palestrantes", "/speakers"]} exact component={Speakers} />
      <Route path={["/comite-tecnico", "/technical-committee"]} exact component={TechnicalCommittee} />
      <Route path={["/comite-estrategico", "/strategic-committee"]} exact component={StrategicCommitte} />
      <Route path={["/comite-internacional", "/international-committee"]} exact component={InternationalCommittee} />
      <Route path={["/patrocinadores", "/sponsors"]} exact component={Sponsors} />
      <Route path={["/call-for-papers", "/call-for-papers"]} exact component={PicturesRosen} />
      <Route path={["/call-for-papers-2", "/call-for-papers-2"]} exact component={PicturesRosen} />
      <Route path={["/noticias", "/news"]} exact component={New} />
      <Route path={["/noticias/:slug", "/news/:slug"]} exact component={Posts} />
      <Route path={["/busca", "/search"]} exact component={Seacrh} />
      <Route path={["/categoria/:slug", "/category/:slug"]} exact component={Category} />
      <Route path={["/mapa", "/map"]} exact component={Mapa} />
      <Route path={["/eventos-parceiros", "/partners-event"]} exact component={PartnerEvents} />


      <Route path="/" exact component={Home} />
      <Route exact path="/*" component={PageNotFound} />
      
    </Switch>
  )
}