import { gql } from '@apollo/client'

export const GET_COMMITTEE_ORGANIZING = gql`
  query GetCommitteeOrganizing {
    comitesOrganizadores(first: 1000) {
      nodes {
        id
        title
        content
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }
        acfFieldsComitesOrganizadores {
          cargo
          chairEstrategico
          chairInternacional
          chairOrganizador
          chairTecnico
          coChairEstrategico
          coChairInternacional
          coChairOrganizador
          coChairTecnico
          comite
          link
        }
        acfIdioma {
          idioma
        }
      }
    }
  }
`

export const GET_PATROCINADORES = gql`
  query GetPatrocinadores {
    patrocinadoresCategorias(first: 100) {
      nodes {
        name
        id
        patrocinadores(first: 1000) {
          nodes {
            id
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            acfLink {
              link
            }
          }
        }
      }
    }
  }
`

export const GET_FAQ = gql`
  query GetFaq($language: String) {
    faqs(where: { idioma: $language }, first:100) {
      nodes {
        id
        title
        content
        translations {
          locale
        }
      }
    }
  }
  
 
`

export const GET_SEARCH_RESULTS = gql`
  query GetSearchResults($term: String!, $per_page: Int, $after: String, $language: String) {
    posts(where: {search: $term, idioma: $language}, first: $per_page, after: $after) {
      nodes {
        id
        title
        excerpt
        slug
        categories {
          nodes {
            categoryId
            name
            slug
          }
        }        
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

export const GET_POSTS = gql`
  query GetPost($per_page: Int, $after: String, $language: String) {
    posts(first: $per_page, after: $after, where: { idioma: $language }) {
      nodes {
        id
        title
        slug
        categories {
          nodes {
            categoryId
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }
        translations {
          locale
        }        
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
export const GET_BANNERS = gql`
  query GetBanners( $language: String ) {
    banners( where: { idioma: $language }) {
      nodes {
        id
        title
        content
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }      
      }
    }
  }    
  
`
export const GET_POST = gql`
  query GetPost($slug: String!) {
    postBy(slug: $slug) {
      id
      title
      content
      date    
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      categories {
        nodes {
          id
          name
          slug
        }
      }
      translated {
        slug
      }
      afcFieldsPosts {
        materiasRelacionadas {
          ... on Post {
            id
            title
            slug
            categories {
              nodes {
                id
                name
                slug
              }
            }
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategory($slug: String, $per_page: Int, $after: String) {
    categories(where: {slug: [$slug]}) {
      nodes {
        posts(first: $per_page, after: $after) {
          nodes {
            id
            title
            slug
            featuredImage {
              node {
                altText
                mediaItemUrl
              }
            }
            categories {
              nodes {
                id
                name
                slug
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
`

export const GET_SHEDUlES = gql`
  query getShedules {
    programacoesDatasPalestras {
      nodes {
        name
        id
        programacoes(last: 1000) {
          nodes {
            id
            title
            acfprogramacao {
              datePalestra
              destaqueDaPalestra
              horarioDeInicio
              horarioTermino
              localPalestra
              resumoDaPalestra
              sigla
              subTitulo
              temas
              cadastroPalestrante {
                empresa
                nome
              }
            }
            acfIdioma {
              idioma
            }
          }
        }
      }
    }
  }
`

export const GET_SOCIAL_NETWORKS = gql`
query SocialNetworks {
  redesSociais {
    nodes {
      afcFieldsRedesSociais {
        icones {
          link
          nomeRedeSocial
          icone {
            altText
            mediaItemUrl
            id
          }
        }
      }
    }
  }
}

`

export const GET_EXPOSITORS = gql`
query Expositors {
  expositores(first: 1000) {
    nodes {
      id
      title
      acfFieldsExpositores {
        estande
        website
        imagem {
            mediaItemUrl
            altText
        }
      }
    }
  }
}
`
