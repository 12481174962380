import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container } from "./styles";
import { IconContext } from "react-icons";

import imgBR from "../../assests/Imagens_IBP/Home/pt-br.png";
import imgEn from "../../assests/Imagens_IBP/Home/en.png";
import imgEs from "../../assests/Imagens_IBP/Home/es.svg";

import imgIcon from "../../assests/images/icon-search.png";

import FormTop from "../FormTop";
import { useLanguage } from "../../hooks/useLanguage";
import { useSideBar } from "../../hooks/useSidebar";

function Topbar() {
  let history = useHistory();
  const { toggleLaguage } = useLanguage();
  const [searchText, setSearchText] = useState("");
  const [toggleSearch, setToggleSearch] = useState(false);
  const { language } = useLanguage();
  const { sidebar, showSidebar } = useSideBar();

  useEffect(() => {
    const body = document.querySelector("body");

    if (sidebar) {
      if (body) {
        body.style.overflow = "hidden";
        body.style.height = "100vh";
      }
    } else if (body) {
      body.style.overflow = "";
      body.style.height = "";
    }
  }, [sidebar]);

  function handleSubmitSearch(event) {
    event.preventDefault();

    if (!searchText) {
      setToggleSearch(!toggleSearch);
    } else {
      if (language === "pt_BR") {
        history.push(`/busca?q=${searchText}`);
      } else {
        history.push(`/search?q=${searchText}`);
      }
      setToggleSearch(!toggleSearch);
      setSearchText("");
    }
  }

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkUrl = () => {
      setShowButton(window.location.href.includes("call-for-papers-2") || window.location.href.includes("call-for-papers"));
    };

    checkUrl();
    
    return history.listen((_) => {
      checkUrl();
    });
  }, [history]);

  return (
    <Container>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="topbar">
          <ul className="topbar-list">
            <li className="topbar-list__item">
              <form
                onSubmit={handleSubmitSearch}
                action="/busca"
                method="get"
                className={`${
                  toggleSearch ? "form-search active" : "form-search"
                }`}
              >
                <input
                  type="text"
                  placeholder={
                    language === "en_US"
                      ? "What are you search?"
                      : "O que você procura?"
                  }
                  name="q"
                  value={searchText}
                  onInput={(event) => setSearchText(event.target.value)}
                />
                <button type="submit">
                  <img src={imgIcon} alt="Busca" />
                </button>
              </form>
            </li>
            <li className="topbar-list__item">
              <Link to="faq" className="title">
                <span className="item__list">
                  {language === "en_US" ? "FAQ" : "FAQ"}
                </span>
              </Link>
            </li>
            <li className="topbar-list__item topbar-list__item--img fale-conosco">
              <Link to="#" className="title">
                <span className="item__list" onClick={showSidebar}>
                  {language === "en_US" ? "Contact us" : " Fale Conosco"}
                </span>
              </Link>
            </li>
            <li className="topbar-list__item topbar-list__item--img">
              <span onClick={() => toggleLaguage("pt_BR")}>
                <img src={imgBR} alt="bandeira Brasil" />
              </span>

              <span onClick={() => toggleLaguage("en_US")}>
                <img src={imgEn} alt="bandeira EUA" />
              </span>
              {showButton && (
                <span onClick={() => toggleLaguage("es_ES")}>
                  <img style={{objectFit: 'cover'}} src={imgEs} alt="bandeira Espanha" />
                </span>
              )}
            </li>
          </ul>
        </div>

        <div className={sidebar ? "topbar-form active" : "topbar-form"}>
          <FormTop onclick={showSidebar} />
        </div>
      </IconContext.Provider>
    </Container>
  );
}

export default Topbar;
