//_Patrocinadores_
import React, { useEffect, useState } from 'react'
import { useLanguage } from '../../hooks/useLanguage'

import { ContainerSponsors } from "./styles"
import { client } from '../../Services/wordpress'
import { removerAcentos } from '../../utils/removeChars'
import { GET_PATROCINADORES } from '../../queries/pages'

function GridSponsors() {
  const [sponsors, setSponsors] = useState([])
  const { language } = useLanguage()

  useEffect(() => {
    getDate()
  }, [])

  async function getDate() {
    try {
      const res = await client.query({
        query: GET_PATROCINADORES
      })

      const { data, errors } = res

      if (errors) {
        console.error("request falhed")
        return
      }

      const newData = data.patrocinadoresCategorias.nodes.map(
        patrocinador => {
          return {
            id: patrocinador.id,
            title: patrocinador.name,
            class: removerAcentos(patrocinador.name.toLowerCase().replaceAll(" ", "-")),
            sponsors: patrocinador.patrocinadores.nodes.map(
              parceiro => {
                return {
                  id: parceiro.id,
                  image: parceiro.featuredImage?.node.mediaItemUrl,
                  alt: parceiro.featuredImage?.node.altText,
                  link: parceiro.acfLink.link
                }
              }
            )
          }
        }
      )

      setSponsors(newData)
    } catch (error) {
      console.error("request fail")
      // console.error(error) // TODO Habilitar somente em degub
    }
  }

  return (
    <ContainerSponsors>
      <h2>
        {language === 'en_US'
          ? 'Meet the 2025 Sponsors'
          : 'Conheça os Patrocinadores de 2025'}
      </h2>

      {/* {language === 'en_US'
          ? (
            <div >
              <img className='patrocinadores-fullimage' src='https://painel.riopipeline.com.br/wp-content/uploads/2024/12/Patrocinio-Silver-en-e1736193714867.png'/>
            </div>
          )
          : (<div >
             <img className='patrocinadores-fullimage' src='https://painel.riopipeline.com.br/wp-content/uploads/2024/12/Patrocinio-Silver-pt-e1736193602225.png'/>
          </div>)} */}

      
      { sponsors.map(sponsor => {
        if (sponsor.sponsors.length > 0) {
          return (
            <div key={sponsor.id} className="box-sponsors">
              <h3>
                {
                  language === "en_US"
                    ? sponsor.title
                      .replaceAll("Patrocínio", "Sponsor")
                      .replaceAll("Ouro", "Gold")
                      .replaceAll("Prata", "Silver")
                      .replaceAll("Cobre", "Copper")
                      .replaceAll("Parceiro de Mídia", "Media Partners")
                      .replaceAll("Apoio Institucional", "Institutional Support")
                      .replaceAll("Parceria Institucional", "Institutional Partnership")
                    : sponsor.title.replaceAll("Parceiro de Mídia", "Parceiros de Mídia")
                }
              </h3>

              <div className={`brands-sponsors ${sponsor.class}`}>
                {sponsor.sponsors.map(brand => {
                  if (brand.image) {
                    return (
                      <a key={brand.id} className="__item" href={brand.link} rel="noopener noreferrer" target="_black">
                        <img src={brand.image} alt={brand.alt} width="375" heigth="110" />
                      </a>
                    )
                  }

                  return null
                })}
              </div>
            </div>
          )
        }
        return null
      })}


    </ContainerSponsors>

  )
}

export default GridSponsors
